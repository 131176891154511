import React from "react";

import Layout from "../globals/index";

export default () => (
    <Layout>
        <div>
            <h1>About Page</h1>
        </div>
    </Layout>
)